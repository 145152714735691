import * as React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { SocialMediaIcons } from '../SocialMediaIcons';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from '../../App';
import './styles.css';

const pages = ['Shows', 'Band', 'Media', 'EPK', 'Contact'];

export const Nav = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const largeScreenGap = '50px';
    const smallScreenGap = '10px';

    const gap = isLargeScreen ? largeScreenGap : smallScreenGap;

    const isActive = (item: string) => {
        const path = location.pathname.slice(1).toLowerCase();
        const itemAsLowerCase = item.toLowerCase();
        return path === itemAsLowerCase || (path === '' && itemAsLowerCase === 'home');
    };

    const getButtonCss = (page: string) => {
        const active = isActive(page);
        return {
            my: 2,
            width: '100%',
            fontSize: '16px',
            letterSpacing: '3.2px',
            textTransform: 'uppercase',
            color: active ? 'black' : 'white',
            backgroundColor: active ? '#FFD5AA' : null,
            '&:hover': {
                backgroundColor: active ? '#fce4cc' : null,
            },
            '&:focus': { opacity: 0.5 },
            '&:active': { opacity: 1 },
        };
    };

    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    return (
        <AppBar
            position="sticky"
            sx={{
                display: 'flex',
                backgroundColor: '#000000',
                boxShadow: '0px 0px 0px 0px',
                top: 0,
                pt: 3,
                pr: { xl: 25 },
                pl: { xl: 25 },
                zIndex: 1,
            }}
        >
            <Toolbar sx={{ maxHeight: '45px' }}>
                <Box
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', gap }}
                >
                    <Box sx={{ display: 'flex', overflow: 'hidden', ml: { xs: -15, md: 0 } }}>
                        <Logo className="logo" onClick={() => navigate('/')} />
                    </Box>
                    {isMediumScreen && (
                        <React.Fragment>
                            <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center', gap }}>
                                {pages.map((page) => (
                                    <Button
                                        key={page}
                                        onClick={() => navigate(page === 'Home' ? '/' : `/${page}`)}
                                        size={'large'}
                                        sx={getButtonCss(page)}
                                    >
                                        <Typography sx={{ fontWeight: '700' }}>{page}</Typography>
                                    </Button>
                                ))}
                            </Box>
                            <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'right' }}>
                                <SocialMediaIcons />
                            </Box>
                        </React.Fragment>
                    )}
                </Box>
                <Box sx={{ flexGrow: 1, justifyContent: 'right', display: { xs: 'flex', md: 'none' } }}>
                    <IconButton
                        size="large"
                        aria-label="open navigation menu"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={toggleDrawer(true)}
                        sx={{ color: 'white' }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Drawer anchor={'right'} open={open} onClose={toggleDrawer(false)} sx={{ color: 'white' }}>
                        <Box
                            sx={{ width: 250 }}
                            role="presentation"
                            onClick={toggleDrawer(false)}
                            onKeyDown={toggleDrawer(false)}
                        >
                            <List>
                                <ListItem
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly',
                                    }}
                                >
                                    <SocialMediaIcons />
                                </ListItem>
                                {pages.map((page) => (
                                    <ListItem key={page} disablePadding>
                                        <ListItemButton
                                            sx={getButtonCss(page)}
                                            onClick={() => navigate(page === 'Home' ? '/' : `/${page}`)}
                                        >
                                            <ListItemText
                                                sx={{ textAlign: 'center' }}
                                                primary={<Typography sx={{ fontWeight: '700' }}>{page}</Typography>}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </Drawer>
                </Box>
            </Toolbar>
        </AppBar>
    );
};
