import React from 'react';
import { Grid, Card, CardMedia } from '@mui/material';
import image1 from '../../assets/gallery/202208270816.jpg';
import image2 from '../../assets/gallery/202208270824.jpg';
import image3 from '../../assets/gallery/CRR-Fri. After Party-Northwoods Jam 2023 (80).jpg';
import image4 from '../../assets/gallery/CRR-Samuel John Band-Northwoods Jam 2023 (2).jpg';
import image5 from '../../assets/gallery/CRR-Samuel John Band-Northwoods Jam 2023 (6).jpg';
import image6 from '../../assets/gallery/CRR-Samuel John Band-Northwoods Jam 2023 (12).jpg';
import image7 from '../../assets/gallery/IMG_0004.jpg';
import image8 from '../../assets/gallery/IMG_0005.jpg';
import image9 from '../../assets/gallery/IMG_0135.jpg';
import image10 from '../../assets/gallery/IMG_0147.jpg';
import image11 from '../../assets/gallery/IMG_0249.jpg';
import image12 from '../../assets/gallery/IMG_0366.jpg';
import image16 from '../../assets/gallery/IMG_0436.jpg';
import image13 from '../../assets/gallery/IMG_0462.jpg';
import image14 from '../../assets/gallery/IMG_0648.jpg';
import image15 from '../../assets/gallery/IMG_1292.jpg';

export const ResponsiveImageGrid: React.FC = () => {
    const images = [
        image13,
        image5,
        image6,
        image16,
        image2,
        image4,
        image7,
        image8,
        image9,
        image11,
        image3,
        image1,
        image10,
        image12,
        image14,
        image15,
    ];

    return (
        <Grid container spacing={2}>
            {images.map((image, index) => (
                <Grid item xs={6} sm={6} lg={3} key={index}>
                    <Card>
                        <CardMedia component="img" alt={`Image ${index}`} src={image} />
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};
