import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
    <div
        style={{
            minWidth: '300px',
            height: '100vh',
            width: '100%',
        }}
    >
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </div>,
    document.getElementById('root'),
);
