import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Nav } from '../components/Nav';
import Divider from '@mui/material/Divider';
import { BandsInTown } from '../components/Events/BandsInTown';

const m = { md: 6, xs: 6 };

export const Shows = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <React.Fragment>
            <Nav />
            <Box
                sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                    alignContent: 'center',
                }}
            >
                <Box sx={{ m }} />
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '3rem',
                        letterSpacing: '0.3em',
                        textTransform: 'uppercase',
                        color: '#FFFFFF',
                    }}
                >
                    SHOWS
                </Typography>
                <Box sx={{ m: 2 }} />
                <Divider color={'white'} sx={{ width: '75%', mr: 'auto', ml: 'auto' }}></Divider>
                <Box sx={{ m: 2 }} />
                {/* <EventList showAll={true} /> */}
                <BandsInTown />
                <Box sx={{ m }} />
            </Box>
        </React.Fragment>
    );
};
