import React, { useState, useEffect, useRef, CSSProperties } from 'react';

type Props = {
    src: string;
    placeholder: string;
    alt: string;
    style?: CSSProperties;
};

export const ImagePreloader = (props: Props) => {
    const [loaded, setLoaded] = useState(false);
    const imgRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const img = new Image();
                    img.onload = () => {
                        setLoaded(true);
                    };
                    img.src = props.src;
                    observer.unobserve(imgRef.current!);
                }
            });
        });
        observer.observe(imgRef.current!);
    }, [props.src]);

    return (
        <img ref={imgRef} src={props.placeholder} alt={props.alt} style={{ ...props.style, opacity: loaded ? 1 : 0 }} />
    );
};
