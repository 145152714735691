export const buttonCss = {
    backgroundColor: '#FFD5AA',
    color: 'black',
    minWidth: '124px',
    height: '50px',
    whiteSpace: 'nowrap',
    '&:hover': {
        backgroundColor: '#fce4cc',
    },
    '&:focus': { opacity: 0.5 },
};
