import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Nav } from '../components/Nav';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import NorthwoodsJam2022 from '../assets/notableShows/NorthwoodsJam2022.jpg';
import NorthwoodsJam2023 from '../assets/notableShows/NorthwoodsJam2023.jpg';
import RayScott from '../assets/notableShows/RayScott.jpg';
import DevonWorley from '../assets/notableShows/DevonWorley.jpg';
import MattPudas from '../assets/notableShows/MattPudas.jpg';
import SeventhStreet from '../assets/notableShows/SeventhStreet.jpg';
import { ResponsiveImageGrid } from '../components/ResponsiveImageGrid';
import Button from '@mui/material/Button';
import { buttonCss } from '../helpers/cssHelpers';
import EmailIcon from '@mui/icons-material/Email';

const cardData = [
    { content: 'Northwoods Jam Music Fesitval 2023', image: NorthwoodsJam2023 },
    { title: 'Opening for Ray Scott', content: 'Ryders Saloon 2023', image: RayScott },
    {
        title: "Opening for Devon Worley's Album Release Show",
        content: 'Amsterdam Bar & Hall 2023',
        image: DevonWorley,
    },
    { title: 'Opening for Matt Pudas', content: 'Ryders Saloon 2023', image: MattPudas },
    { content: 'Northwoods Jam Music Fesitval 2022', image: NorthwoodsJam2022 },
    { title: 'Show w/Devon Worley & Russ Parrish', content: "First Ave's 7th Street Entry 2024", image: SeventhStreet },
];

export const PressKit = () => {
    const email = 'SamuelJohnBand@gmail.com';

    const handleEmailClick = () => {
        window.open(`mailto:${email}`, '_blank');
    };

    return (
        <Box>
            <Nav />
            <Box
                sx={{
                    height: '100%',
                    maxWidth: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'left',
                }}
                mr={{ xl: 30, l: 15, md: 10, sm: 5, xs: 2 }}
                ml={{ xl: 30, l: 15, md: 10, sm: 5, xs: 2 }}
            >
                <Box sx={{ m: 2 }} />
                <Box sx={{ m: 2 }} />
                <Typography
                    sx={{
                        fontWeight: 700,
                        fontSize: '55px',
                        textTransform: 'uppercase',
                        color: '#FFFFFF',
                        maxWidth: '100%',
                    }}
                >
                    Press Kit
                </Typography>
                <Box sx={{ m: 2 }} />
                <Typography
                    sx={{
                        fontWeight: 700,
                        fontSize: '30px',
                        textTransform: 'uppercase',
                        color: '#FFFFFF',
                        maxWidth: '100%',
                    }}
                >
                    Short Bio
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '16px',
                        color: '#FFFFFF',
                        maxWidth: '100%',
                        lineHeight: '32px',
                    }}
                >
                    {`The Samuel John Band is a dynamic country rock ensemble rooted in the heart of Minnesota. Hailing from the small town of Braham, MN, Samuel John found his musical calling through the inspiring performances of his mother, a pianist and vocalist, and witnessing his brother Ben drum for a touring country band. Samuel, also a frequent concert-goer, drew inspiration from watching his favorite songwriters in the independent country and rock scene. Joined by longtime friend Jake Abel on bass, whose experience spans multiple bands, and meeting Brandon Nelson, the lead guitarist, through spontaneous jams at the Monday night Terminal Bar open sessions in Minneapolis, the band found its core. John Wander, previously collaborating with Brandon, completed the lineup on drums. Together, they’re doing their best to create an authentic and original country rock sound.`}
                </Typography>
                <Box sx={{ m: 2 }} />
                <Button
                    sx={{ alignSelf: 'center', ...buttonCss }}
                    variant="contained"
                    color="primary"
                    startIcon={<EmailIcon />}
                    onClick={handleEmailClick}
                >
                    SamuelJohnBand@gmail.com
                </Button>
                <Box sx={{ m: 4 }} />
                <Typography
                    sx={{
                        fontWeight: 700,
                        fontSize: '30px',
                        textTransform: 'uppercase',
                        color: '#FFFFFF',
                        maxWidth: '100%',
                    }}
                >
                    {'Our Debut Single "Set Me Free" out now!'}
                </Typography>
                <Box sx={{ m: 2 }}>
                    <iframe
                        title="Set Me Free on Spotify"
                        style={{ borderRadius: 12 }}
                        src="https://open.spotify.com/embed/track/3lSYVJNuJnV4zZtFUEIeV2?utm_source=generator&theme=0"
                        width="100%"
                        height="352"
                        frameBorder="0"
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                        loading="lazy"
                    ></iframe>
                    {/* <iframe
                        title="Set Me Free on Apple Music"
                        allow="autoplay *; encrypted-media *;"
                        frameBorder="0"
                        height="150"
                        style={{ width: '100%', maxWidth: '660px', overflow: 'hidden', background: 'transparent' }}
                        sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                        src="https://embed.music.apple.com/us/album/set-me-free/1722170114?i=1722170115"
                    ></iframe> */}
                </Box>
                <Box sx={{ m: 4 }} />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: { xs: 'column', md: 'row' },
                    }}
                >
                    <Box sx={{ position: 'relative', width: '100%', maxWidth: '800px' }}>
                        <div style={{ position: 'relative', paddingTop: '56.25%', maxHeight: '400px' }}>
                            <iframe
                                title="Set Me Free at RiverRock Studios"
                                src="https://www.youtube.com/embed/vsXaqSLnHg4?si=rThIch0XsGbgNGCN"
                                frameBorder="0"
                                allowFullScreen
                                style={{
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    width: '100%',
                                    height: '100%',
                                }}
                            ></iframe>
                        </div>
                    </Box>
                    <Box sx={{ position: 'relative', width: '100%', maxWidth: '800px' }}>
                        <div style={{ position: 'relative', paddingTop: '56.25%', maxHeight: '400px' }}>
                            <iframe
                                title="Set Me Free Live at Northwoods Jam 2023"
                                src="https://www.youtube.com/embed/BajXVOTdKls?si=7C5J0voaBwjlgkbl"
                                frameBorder="0"
                                allowFullScreen
                                style={{
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    width: '100%',
                                    height: '100%',
                                }}
                            ></iframe>
                        </div>
                    </Box>
                </Box>
                <Box
                    sx={{
                        height: '100%',
                        maxWidth: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'left',
                    }}
                >
                    <Box sx={{ m: 2 }} />
                    <Box sx={{ m: 2 }} />
                    <Box sx={{ m: 2 }} />
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: '30px',
                            textTransform: 'uppercase',
                            color: '#FFFFFF',
                            maxWidth: '100%',
                        }}
                    >
                        Notable Shows
                    </Typography>
                    <Box sx={{ m: 2 }} />
                    <Grid container spacing={3}>
                        {cardData.map((card, index) => (
                            <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl={4}>
                                <Card sx={{ minHeight: '300px' }}>
                                    <CardMedia
                                        component="img"
                                        height="200"
                                        image={card.image}
                                        alt={`${card.content}`}
                                        style={{ objectFit: 'cover' }}
                                    />
                                    <CardContent sx={{ flexGrow: 1 }}>
                                        <Typography sx={{ color: '#FFFFFF', fontWeight: 400 }}>{card.title}</Typography>
                                        <Typography sx={{ color: '#FFFFFF', fontWeight: 700 }}>
                                            {card.content}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    <Box sx={{ m: 2 }} />
                    <Box sx={{ m: 2 }} />
                    <Box sx={{ m: 2 }} />
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: '30px',
                            textTransform: 'uppercase',
                            color: '#FFFFFF',
                            maxWidth: '100%',
                        }}
                    >
                        Media
                    </Typography>
                    <Box sx={{ m: 2 }} />
                    <ResponsiveImageGrid />
                </Box>
            </Box>
            <Box sx={{ height: 200 }} />
        </Box>
    );
};
