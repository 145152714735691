import React from 'react';
import { Nav } from '../components/Nav';
import Box from '@mui/material/Box';
import { ResponsiveImageGrid } from '../components/ResponsiveImageGrid';
import Divider from '@mui/material/Divider';

export const Media = () => (
    <React.Fragment>
        <Nav />
        <Box
            sx={{
                width: '100%',
                maxWidth: '100%',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                alignContent: 'center',
            }}
        >
            <Box sx={{ m: 1 }} />
            <Divider
                color={'white'}
                sx={{ width: '75%', mr: 'auto', ml: 'auto', display: { xs: 'none', md: 'flex' } }}
            ></Divider>
            <Box sx={{ m: 1 }} />
            <Box p={{ xs: 2, maxWidth: '100%', justifyContent: 'center', display: 'flex' }}>
                <ResponsiveImageGrid />
            </Box>
        </Box>
    </React.Fragment>
);
