import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Info } from './Info';
import { ImagePreloader } from '../ImagePreloader';

export const BioGrid = () => {
    const shuffledPeople = [...Info.slice(1)].sort(() => Math.random() - 0.5);
    const randomizedPeople = [Info[0], ...shuffledPeople];
    return (
        <React.Fragment>
            {randomizedPeople.map((info, index) => {
                const { name, image, bio } = info;
                return (
                    <Box key={name} display={'flex'} p={{ sm: 10 }} sx={{ backgroundColor: '#161616', mb: 2 }}>
                        <Grid
                            container
                            spacing={4}
                            sx={{ flexDirection: index % 2 === 0 ? 'row' : 'row-reverse', alignItems: 'center' }}
                        >
                            <Grid item xs={12} md={6}>
                                <ImagePreloader
                                    src={image}
                                    placeholder={image}
                                    alt={'Samuel John'}
                                    style={{ maxHeight: '450px', maxWidth: '100%' }}
                                />
                                {/* <img
                                    src={image}
                                    style={{ maxHeight: '450px', maxWidth: '100%' }}
                                    srcSet={image}
                                    alt={'Samuel John'}
                                    key={image}
                                /> */}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h3" component="h2" gutterBottom color={'white'} mb={4}>
                                    {name}
                                </Typography>
                                <Typography variant="body1" component="p" paragraph color={'white'}>
                                    {bio}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                );
            })}
        </React.Fragment>
    );
};
