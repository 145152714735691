import { Bio } from './types';
import Sam from '../../assets/bioPics/sam.jpg';
import Jake from '../../assets/bioPics/jake.jpg';
import Brandon from '../../assets/bioPics/brandon.jpg';
import John from '../../assets/bioPics/john.jpg';

export const Info: Bio[] = [
    {
        name: 'Samuel John',
        image: Sam,
        bio: `Meet Samuel John, from Isanti County, MN, who's attempting to carve his own path in the world of country and rock music. Inspired by watching his mother play piano and sing professionally at events growing up, Sam always had an interest in the arts.
        It was his younger brother, Ben Higgins, who started playing on stage shortly before the pandemic, that sparked Samuel's interest in playing live music. Ben, along with his friend Cody Dylan Brown (from Mora, MN), showed Samuel the ropes of being a gigging musician and songwriter, and were the first people to bring him on stage.
        Samuel's musical influences include classic artists such as Waylon Jennings, Merle Haggard, and Johnny Cash, as well as modern musicians like Cody Jinks, Tyler Childers, Sturgill Simpson, and Whiskey Myers.
        During the COVID-19 lockdown, Samuel spent his time writing and practicing his own material in preparation for when bars and venues would reopen. After covering some of his favorite artists and songwriters during his gigs, he began to introduce his original music to his audiences.
        In 2023, Samuel teamed up with his friends Brandon Nelson, Jake Abel, and John Wander to form the Samuel John Band. Together, they play a mix of original songs and covers, showcasing a unique blend of traditional and outlaw country and rock.`,
    },
    {
        name: 'John Wander',
        image: John,
        bio: `John Wander joins us from the small town of Moorhead, MN with approximately 20 years of learn-by-ear drumming & performance experience. John started his music career as a backup drummer for Western Minnesota Steam Threshers Reunion at the age of 10, and over the next 20 years, has had the opportunity to play with a variety of jazz, country and rock groups within and outside of MN area. In 2022, Brandon Nelson had reached out to John after collectively closing the doors on their previous country/rock group by the name of "Church Clothes" to connect with Sam and become the main drummer for the group. When John isn't working on his drum fills, he's working his day job as a digital creative or his Monsters Playbook Podcast. When he's not working, you can find him hanging out at home with his wife and 2 cats, or on his motorcycle heading down to Lake Pepin for some cheese curds.`,
    },
    {
        name: 'Brandon Nelson',
        image: Brandon,
        bio: `Brandon has taken a few unusual twists and turns in his two decades of playing live music. He started out playing bass and guitar with the gospel band at his hometown church in Stillwater at 14 before joining heavy metal and prog rock bands after high-school. Saint Paul funk group Crooke was where he hung his hat for seven years until the pandemic put live music on pause. Fortunately a chance encounter with Sam at on open mic night rekindled the desire to get back on stage.
        These days he lives with his wife and three cats, leads the Worship team at his church, and hosts the weekly open-mic at the Terminal Bar in Northeast Minneapolis.`,
    },
    {
        name: 'Jake Abel',
        image: Jake,
        bio: `This is our pretty boy bassist Jabel. His career as a backup dancer hasn’t taken off yet so he’s sticking to teaching music to America’s youth, restaurant managing, and door dashing cuz times are tight. A memorable moment in Jake’s life involved him winning a pac-man machine as a prize for drinking the most beers in one month at 7 West Taphouse. If you’re feeling a bit uptight, no need to get laid, just go bowling with Jake. His truly chaotic energy will be sure to turn your frown upside down.`,
    },
];
