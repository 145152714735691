import React, { useState } from 'react';

import emailjs from '@emailjs/browser';
import { TextField, Button, Snackbar, Alert, AlertColor, Box } from '@mui/material';
import { buttonCss } from '../helpers/cssHelpers';

export const ContactForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState<{ severity: 'error' | 'info' | 'success' | 'warning'; message: string }>({
        severity: 'info',
        message: '',
    });

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();

        emailjs
            .send(
                'service_djenusf',
                'template_clwbksj',
                {
                    name,
                    message,
                    email,
                },
                '8auIAuKTEz0qlKJCS',
            )
            .then(
                (result: any) => {
                    // eslint-disable-next-line no-console
                    console.log(result.text);
                    setName('');
                    setEmail('');
                    setMessage('');
                    setOpen(true);
                    setAlert({
                        severity: 'success',
                        message: 'Your message has been sent successfully.',
                    });
                },
                (error: any) => {
                    // eslint-disable-next-line no-console
                    console.log(error.text);
                    setOpen(true);
                    setAlert({
                        severity: 'error',
                        message: 'There was an error sending your message. Please try again later.',
                    });
                },
            );
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
            }}
        >
            <form onSubmit={handleSubmit}>
                <TextField
                    required
                    fullWidth
                    margin="normal"
                    label="Your Name"
                    name="your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    sx={{ backgroundColor: '#FFFFFF' }}
                />
                <TextField
                    required
                    fullWidth
                    margin="normal"
                    label="Your Email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{ backgroundColor: '#FFFFFF' }}
                />
                <TextField
                    required
                    fullWidth
                    multiline
                    minRows={3}
                    margin="normal"
                    label="Message"
                    name="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    sx={{ backgroundColor: '#FFFFFF' }}
                />
                <Box mb={2} />
                <Button type="submit" variant="contained" color="primary" sx={buttonCss}>
                    Submit
                </Button>
            </form>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={alert.severity as AlertColor}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};
