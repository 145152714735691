import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ContactForm } from '../components/ContactForm';
import { Nav } from '../components/Nav';
import EmailIcon from '@mui/icons-material/Email';
import { buttonCss } from '../helpers/cssHelpers';

const m = { md: 6, xs: 6, lg: 12 };

export const Contact = () => {
    const email = 'SamuelJohnBand@gmail.com';

    const handleEmailClick = () => {
        window.open(`mailto:${email}`, '_blank');
    };

    return (
        <React.Fragment>
            <Nav />
            <Box
                sx={{
                    height: '100%',
                    maxWidth: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                    alignContent: 'center',
                }}
                mr={{ md: 'auto', xs: 2 }}
                ml={{ md: 'auto', xs: 2 }}
            >
                <Box sx={{ m }} />
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '3rem',
                        letterSpacing: '0.3em',
                        textTransform: 'uppercase',
                        color: '#FFFFFF',
                        maxWidth: '100%',
                    }}
                >
                    Email us!
                </Typography>
                <Box sx={{ m: 2 }} />
                <Typography
                    sx={{
                        fontWeight: 400,
                        fontSize: '16px',
                        color: '#FFFFFF',
                        maxWidth: '100%',
                    }}
                >
                    Use your own email service here or submit the form below:
                </Typography>
                <Box sx={{ m: 2 }} />
                <Button
                    sx={{ alignSelf: 'center', ...buttonCss }}
                    variant="contained"
                    color="primary"
                    startIcon={<EmailIcon />}
                    onClick={handleEmailClick}
                >
                    SamuelJohnBand@gmail.com
                </Button>
                <Box sx={{ m: 2 }} />
                <ContactForm />
                <Box sx={{ m }} />
                <Box sx={{ m }} />
            </Box>
        </React.Fragment>
    );
};
