import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Nav } from '../components/Nav';
import { BioGrid } from '../components/Bios/BioGrid';
import Divider from '@mui/material/Divider';

const m = { md: 6, xs: 6, lg: 12 };

export const Bios = () => (
    <React.Fragment>
        <Nav />
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                alignContent: 'center',
            }}
            mr={{ md: 12, xs: 2 }}
            ml={{ md: 12, xs: 2 }}
        >
            <Box sx={{ mb: m }} />
            <Typography
                sx={{
                    fontWeight: 400,
                    fontSize: '3rem',
                    letterSpacing: '0.3em',
                    textTransform: 'uppercase',
                    color: '#FFFFFF',
                }}
            >
                Meet the Band
            </Typography>
            <Box sx={{ m: 2 }} />
            <Divider color={'white'} sx={{ width: '75%', mr: 'auto', ml: 'auto' }}></Divider>
            <Box sx={{ m: 2 }} />
            <BioGrid />
            <Box sx={{ m }} />
            <Box sx={{ m }} />
        </Box>
    </React.Fragment>
);
