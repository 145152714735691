import React from 'react';

import Container from '@mui/material/Container';
import { Hero } from '../components/Hero';
import { Nav } from '../components/Nav';
import { Box } from '@mui/material';
import { BandsInTown } from '../components/Events/BandsInTown';

const m = 4;

export const Intro = () => {
    return (
        <div>
            <Nav />
            <Container
                maxWidth={false}
                sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 100,
                }}
            >
                <Box sx={{ m }} />
                <Hero />
                {/* <Box sx={{ m }} />
                <EventList /> */}
                <Box sx={{ m }} />
                <BandsInTown />
                <Box sx={{ m }} />
            </Container>
        </div>
    );
};
