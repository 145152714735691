import React from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useRoutes } from 'react-router-dom';
import { Intro } from './pages/Intro';
import { Shows } from './pages/Shows';
import { Bios } from './pages/Bios';
import { Media } from './pages/Media';
import { Contact } from './pages/Contact';
import './fonts.css';
import { PressKit } from './pages/PressKit';

export const theme = createTheme({
    typography: {
        fontFamily: 'Montserrat',
    },
    // button: {
    //     active: '#FFD5AA',
    // },
    palette: {
        background: {
            default: '#000000',
            paper: '#131313',
        },
        // primary: {
        //     main: '#FFD5AA',
        // },
    },
});

const App = () => {
    // const classes = useStyles();
    const routes = useRoutes([
        {
            path: '/',
            element: <Intro />,
        },
        {
            path: 'shows',
            element: <Shows />,
        },
        {
            path: 'media',
            element: <Media />,
        },
        {
            path: 'band',
            element: <Bios />,
        },
        {
            path: 'contact',
            element: <Contact />,
        },
        {
            path: 'epk',
            element: <PressKit />,
        },
    ]);
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {routes}
        </ThemeProvider>
    );
};

// eslint-disable-next-line import/no-default-export
export default App;
