import React, { useState } from 'react';

import logo from '../assets/logo.png';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { buttonCss } from '../helpers/cssHelpers';
import { useNavigate } from 'react-router-dom';
import { SocialMediaIcons } from './SocialMediaIcons';

export const Hero = () => {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const handleLoad = () => {
        setIsLoading(false);
    };

    return (
        <Box
            width="100%"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                p: 2,
            }}
        >
            {/* {isLoading || (error && <Skeleton variant="rectangular" width={575} height={500} />)} */}
            <Box width={'575'} height={isLoading ? 300 : '100%'}>
                <img
                    onLoad={handleLoad}
                    src={logo}
                    style={{ maxWidth: '575px', width: '100%' }}
                    srcSet={logo}
                    alt={'Samuel John Band Logo'}
                    key={logo}
                />
            </Box>
            <Box sx={{ m: 8 }} />
            <Box sx={{ display: { md: 'none' } }}>
                <SocialMediaIcons />
                <Box sx={{ m: 2 }} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '10px' }}>
                <Button variant="contained" sx={buttonCss} onClick={() => navigate('/EPK')}>
                    Press Kit
                </Button>
                <Button variant="contained" sx={buttonCss} onClick={() => navigate('/Contact')}>
                    Contact
                </Button>
            </Box>
        </Box>
    );
};
